<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">分发热词</div>
    <div class="chart-container">
      <div class="superposition Demo">
        <div
          v-for="(d, index) in superpositionData"
          :key="d.id"
          @click="handleBefore(d.id)"
          :class="[
      'superposition-item',
      d.isTop ? 'contain-After' : 'contain-Before',
      'superposition_' + index
    ]"
          :style="{ background: 'rgb(' + d.rgb + ')' }"
        >
          <p :class="{ 'normal-text': !d.isTop, 'reversed-text': d.isTop }">{{ d.isTop ? d.falseValue : d.value }}</p>
        </div>


        <div
          v-for="(d, index) in superpositionData"
          :key="d.id"
          @click="handleBefore(d.id)"
          :class="[
  'superposition-item',
  d.isTop ? 'contain-Before' : 'contain-After',
  'superposition_' + index
]"

          :style="{ background: 'rgb(' + d.rgb + ')' }"
        >
          <p :class="{ 'normal-text': d.isTop, 'reversed-text': !d.isTop }">{{ d.isTop ? d.value : d.falseValue }}</p>
        </div>


      </div>

    </div>
  </div>
</template>

<script>
import {dynamicHotWordsApi} from 'api/data'
import {hotWordByData} from 'util/js/homeCharts'

export default {
  name: 'dataStatistics',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      config:{},
      superpositionData: [],
      dynamicHotWords: [],
    }
  },
  created() {
    this.initData();
  },
  methods:{
    initData(){
      dynamicHotWordsApi().then(({ result }) => {
        this.superpositionData = hotWordByData
        this.dynamicHotWords = [...hotWordByData]
        let topArr = result
        let beforeArr = []
        this.addesrarr(topArr, 10)
        beforeArr = this.getArrayItems(topArr, 10)
        beforeArr.map((item1, i) => {
          this.dynamicHotWords[i].value = item1
        })
        topArr = this.getArrayItems(topArr, 10)
        topArr.map((item2, i) => {
          this.dynamicHotWords[i].falseValue = item2
        })
        this.superpositionData.map(res => {
          res.isTop = false
          setInterval(() => {
            this.handleBefore(res.id)
          }, Math.floor(Math.random() * (19999 - 3000)) + 3000)
        })
      })
    },
    getArrayItems(arr, num) {
      let temp_arr = arr.slice(0)
      let return_arr = []
      for (let i = 0; i < num; i++) {
        if (temp_arr.length > 0) {
          let arrIndex = Math.floor(Math.random() * temp_arr.length)
          return_arr[i] = temp_arr[arrIndex]
          temp_arr.splice(arrIndex, 1)
        } else {
          break
        }
      }
      return return_arr
    },
    addesrarr(arrList, num) {
      const shuffle = arr => {
        let newArr = [...arr]
        for (let i = 0; i < newArr.length; i++) {
          const randomIndex =
            Math.round(Math.random() * (newArr.length - 1 - i)) + i
          ;[newArr[i], newArr[randomIndex]] = [newArr[randomIndex], newArr[i]]
        }
        return newArr
      }
      let backupsArr = [...arrList]
      if (arrList.length < num) {
        arrList.push(shuffle(backupsArr)[0])
        this.addesrarr(arrList, num)
      }
    },
    handleBefore(id) {
      console.log("测试111")
      let arr = JSON.parse(JSON.stringify(this.superpositionData))
      arr.map(res => {
        if (res.id == id) {
          res.isTop = !res.isTop
        }
      })
      this.superpositionData = arr
    },
  }
}
</script>

<style lang="scss" scoped>


.normal-text {
  transform: rotateY(0deg); /* 文字正向显示 */
}

//.reversed-text {
//  transform: rotateY(180deg); /* 文字反向显示 */
//}

$shadow: rgba(0, 0, 0, 0.08);
#water-level-chart {
  width: 31%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }


  .contain-Before {
    transition: 1.5s;
    transform: rotateY(180deg);
    // opacity: 0;
    // z-index: -1;
  }

  //.contain-After {
  //  transform: rotateY(0deg);
  //  opacity: 1;
  //}
  //
  //
  //.text-container {
  //  transform: rotateY(180deg); /* 将文字翻转回来 */
  //}

  .superposition {
    position: relative;
    width: 360px; /* 300 * 1.2 */
    height: 180px; /* 150 * 1.2 */
    margin-top: 36px; /* 30 * 1.2 */
    margin-bottom: 12px; /* 10 * 1.2 */
    border-radius: 4.8px; /* 4 * 1.2 */
    overflow: hidden;
    color: #fff;
    font-size: 12px; /* 12 * 1.2 */

    .superposition-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        box-shadow: inset 0 0 80px $shadow;
      }

      p {
        text-align: center;
        line-height: 120%;
        width: fit-content;
        color: #fff; /* 确保文字颜色为白色 */
      }

    }

    .contain-Before {
      transform: rotateY(180deg);
    }

    .contain-After {
      transform: rotateY(0deg);
    }


    .contain-Before, .contain-After {
      transition: 1.5s;
      transform-style: preserve-3d; /* 保持 3D 变换 */
    }

    .contain-Before {
      transform: rotateY(180deg);
      opacity: 0; /* 初始状态为不可见 */
    }

    .contain-After {
      transform: rotateY(0deg);
      opacity: 1;
    }

      .superposition_0 {
        width: 90px; /* 75 * 1.2 */
        height: 180px; /* 150 * 1.2 */
        background: rgb(91, 155, 213);
        position: absolute;
        left: 0;
        top: 0;
      }

      .superposition_1 {
        width: 90px; /* 75 * 1.2 */
        height: 180px; /* 150 * 1.2 */
        background: rgb(237, 125, 49);
        position: absolute;
        left: 90px; /* 75 * 1.2 */
        top: 0;
      }

      .superposition_2 {
        width: 90px; /* 75 * 1.2 */
        height: 48px; /* 40 * 1.2 */
        background: rgb(165, 165, 165);
        position: absolute;
        left: 180px; /* 150 * 1.2 */
        top: 0;
      }

      .superposition_3 {
        width: 90px; /* 75 * 1.2 */
        height: 48px; /* 40 * 1.2 */
        background: rgb(255, 192, 0);
        position: absolute;
        left: 270px; /* 225 * 1.2 */
        top: 0;
      }

      .superposition_4 {
        width: 72px; /* 60 * 1.2 */
        height: 72px; /* 60 * 1.2 */
        background: rgb(68, 114, 196);
        position: absolute;
        left: 180px; /* 150 * 1.2 */
        top: 48px; /* 40 * 1.2 */
      }

      .superposition_5 {
        width: 54px; /* 45 * 1.2 */
        height: 90px; /* 75 * 1.2 */
        background: rgb(112, 173, 71);
        position: absolute;
        left: 252px; /* 210 * 1.2 */
        top: 48px; /* 40 * 1.2 */
      }

      .superposition_6 {
        width: 54px; /* 45 * 1.2 */
        height: 90px; /* 75 * 1.2 */
        background: rgb(112, 173, 71);
        position: absolute;
        left: 306px; /* 255 * 1.2 */
        top: 48px; /* 40 * 1.2 */
      }

      .superposition_7 {
        width: 72px; /* 60 * 1.2 */
        height: 72px; /* 60 * 1.2 */
        background: rgb(112, 173, 71);
        position: absolute;
        left: 180px; /* 150 * 1.2 */
        bottom: 0;
      }

      .superposition_8 {
        width: 54px; /* 45 * 1.2 */
        height: 42px; /* 35 * 1.2 */
        background: rgb(112, 173, 71);
        position: absolute;
        left: 252px; /* 210 * 1.2 */
        bottom: 0;
      }

      .superposition_9 {
        width: 54px; /* 45 * 1.2 */
        height: 42px; /* 35 * 1.2 */
        background: rgb(112, 173, 71);
        position: absolute;
        left: 306px; /* 255 * 1.2 */
        bottom: 0;
      }

    }
  }


</style>
