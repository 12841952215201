var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%", display: "grid" } }, [
    _c("div", { staticStyle: { width: "80%", "place-self": "center" } }, [
      _c(
        "div",
        { staticClass: "form-container" },
        [
          _c("el-card", [
            _c("div", { staticStyle: { margin: "0px auto" } }, [
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("公司信息录入"),
              ]),
            ]),
            _c("form", [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "company-name" } }, [
                  _vm._v("公司简称:"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clientForm.clientName,
                      expression: "clientForm.clientName",
                    },
                  ],
                  attrs: { type: "text", id: "company-name", required: "" },
                  domProps: { value: _vm.clientForm.clientName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.clientForm,
                        "clientName",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "company-website" } }, [
                  _vm._v("公司网址:"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clientForm.clientUrl,
                      expression: "clientForm.clientUrl",
                    },
                  ],
                  attrs: { type: "text", id: "company-website", required: "" },
                  domProps: { value: _vm.clientForm.clientUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.clientForm, "clientUrl", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "company-website" } }, [
                  _vm._v("公司公众号名称:"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clientForm.clientGzhName,
                      expression: "clientForm.clientGzhName",
                    },
                  ],
                  attrs: { type: "text", id: "company-website", required: "" },
                  domProps: { value: _vm.clientForm.clientGzhName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.clientForm,
                        "clientGzhName",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "company-email" } }, [
                  _vm._v("接收邮箱:"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clientForm.clientEmail,
                      expression: "clientForm.clientEmail",
                    },
                  ],
                  attrs: {
                    type: "email",
                    id: "company-email",
                    placeholder: "若有多个请使用逗号隔开",
                    required: "",
                  },
                  domProps: { value: _vm.clientForm.clientEmail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.clientForm,
                        "clientEmail",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "company-phone" } }, [
                  _vm._v("接收手机号:"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clientForm.clientPhone,
                      expression: "clientForm.clientPhone",
                    },
                  ],
                  attrs: {
                    type: "tel",
                    id: "company-phone",
                    placeholder: "若有多个请使用逗号隔开",
                    required: "",
                  },
                  domProps: { value: _vm.clientForm.clientPhone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.clientForm,
                        "clientPhone",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.clientRegisterClick },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.resetClick },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }