<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">BA行业排名</div>
    <div class="chart-container">
      <dv-capsule-chart :config="config" style="width:300px;height:200px" />
    </div>
  </div>
</template>

<script>
import { queryIndustryRankingApi } from 'api/data'
export default {
  name: 'dataStatistics',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      config:{}
    }
  },
  created() {
    this.initData();
  },
  methods:{
    initData(){
      queryIndustryRankingApi(this.dateType).then(res => {
        if (res.code === 200){
          const result = res.result;
          const config = {
            data:[],
            rowNum:0
          };
          const data = [];
          result.forEach(item => {
            const obj = {
              name: item.industry,
              value: item.validSum
            }
            data.push(obj);
          })
          config.data = data;
          config.rowNum = 5;
          this.config = config;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#water-level-chart {
  width: 31%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>
