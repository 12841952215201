<template>
  <div id="digital-flop">
    <div
      class="digital-flop-item"
      v-for="item in digitalFlopData"
      :key="item.title"
    >
      <div class="digital-flop-title">{{ item.title }}</div>
      <div class="digital-flop">
        <dv-digital-flop
          :config="item.number"
          textAlign="center"
          style="width:200%;height:50px;text-align: center"
        />
        <div class="unit">{{ item.unit }}</div>
      </div>
    </div>
    <dv-decoration-10 />
  </div>
</template>

<script>
import { queryReleaseRateByDataApi, queryTopDataApi } from 'api/data'

export default {
  name: 'dataTop',
  props:{
    dateType: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      result:{
        pushTotalNum:0,
        channelTypeNum:0,
        pushApiNum:0,
        readNum:0
      },
      digitalFlopData: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData(){
          queryTopDataApi(this.dateType).then(res => {
            let read = "";
            const readNum = res.result.readNum;
            if (readNum > 100000000) {
              const numInTenThousand = Math.floor(readNum / 100000000);
              const decimalPart = (readNum % 100000000 / 100000000).toFixed(2).substring(1);
              read = (numInTenThousand + decimalPart).toString();
            } else {
              read = (readNum.toFixed(2)).toString();
            }
            queryReleaseRateByDataApi(this.dateType).then(data => {
              if (data.code === 200){
                if (res.code === 200){
                  this.digitalFlopData = [
                    {
                      title: '分发数',
                      number: {
                        number:[res.result.pushTotalNum],
                        content: '{nt}',
                        textAlign: 'right',
                        style: {
                          fill: '#4d99fc',
                          fontWeight: 'bold'
                        }
                      },
                      unit: '篇'
                    },
                    {
                      title: '有效率',
                      number: {
                        number: [data.result],
                        content: '{nt}',
                        textAlign: 'right',
                        style: {
                          fill: '#6127f4',
                          fontWeight: 'bold'
                        }
                      },
                      unit: '%'
                    },
                    {
                      title: '媒介数',
                      number: {
                        // number: [res.result.pushApiNum],
                        number: [res.result.avgPushApiNum],
                        content: '{nt}',
                        textAlign: 'right',
                        style: {
                          fill: '#f46827',
                          fontWeight: 'bold'
                        }
                      },
                      unit: '个'
                    },
                    {
                      title: '阅读量',
                      number: {
                        number: [read],
                        content: read,
                        // toFixed:2,
                        textAlign: 'right',
                        style: {
                          fill: '#40faee',
                          fontWeight: 'bold'
                        }
                      },
                      unit: '亿'
                    },
                  ]
                }
              }
            })


          })
    },
  },
}
</script>

<style lang="scss" scoped>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#digital-flop {
  position: relative;
  //height: 20%;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 95%;
    left: 2.5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
}
</style>