<template>
  <div style='height: 100%;display: grid;'>
    <div style='width: 80%;place-self: center;'>
  <div class="form-container">
    <el-card>
      <div style='margin: 0px auto'>
        <p style='text-align: center'>公司信息录入</p>
      </div>
      <form>
        <div class="form-group">
          <label for="company-name">公司简称:</label>
          <input type="text" id="company-name" v-model="clientForm.clientName" required>
        </div>
        <div class="form-group">
          <label for="company-website">公司网址:</label>
          <input type="text" id="company-website" v-model="clientForm.clientUrl" required>
        </div>
        <div class="form-group">
          <label for="company-website">公司公众号名称:</label>
          <input type="text" id="company-website" v-model="clientForm.clientGzhName" required>
        </div>
        <div class="form-group">
          <label for="company-email">接收邮箱:</label>
          <input type="email" id="company-email" placeholder="若有多个请使用逗号隔开"   v-model="clientForm.clientEmail" required>
        </div>
<!--        <div class="form-group">-->
<!--          <label for="company-province">公司所在地(省):</label>-->
<!--          <input type="text" id="company-province" v-model="clientForm.clientProvince" required>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <label for="company-city">公司所在地(市):</label>-->
<!--          <input type="text" id="company-city" v-model="clientForm.clientCity" required>-->
<!--        </div>-->
        <div class="form-group">
          <label for="company-phone">接收手机号:</label>
          <input type="tel" id="company-phone" placeholder="若有多个请使用逗号隔开"  v-model="clientForm.clientPhone" required>
        </div>
<!--        <div class="form-group">-->
<!--          <label for="company-address">公司办公地址:</label>-->
<!--          <input type="text" id="company-address" v-model="clientForm.clientWorkAddress" required>-->
<!--        </div>-->
        <div align="center">
          <span>
            <el-row>
              <el-col :span='12'>
                <el-button type="primary" @click="clientRegisterClick">确定</el-button>
              </el-col>
              <el-col :span='12'>
                 <el-button type="primary" @click="resetClick">重置</el-button>
              </el-col>
            </el-row>
          </span>
        </div>
<!--        <button type="submit" @click='clientRegisterClick()'>提交</button>-->
      </form>
    </el-card>
  </div>
    </div>
  </div>
</template>

<script>
import { orCodeRegisterApi } from 'api/company'
export default {
  data() {
    return {
      clientForm:{
        clientName: '',
        clientEmail: '',
        clientProvince: '',
        clientCity: '',
        clientUrl: '',
        clientGzhName:'',
        clientPhone: '',
        clientWorkAddress: ''
      },
    };
  },
  created() {
    document.title = '动态宝系统';
  },
  methods: {
    clientRegisterClick() {

      if(this.clientForm.clientName==null||this.clientForm.clientName==""||this.clientForm.clientName=="undefined"){
        this.$message.error("公司名称不能为空!")
        return;
      }
      if (this.clientForm.clientName.length>100){
        this.$message.error("公司名称长度超出限制!")
        return;
      }

      if(this.clientForm.clientUrl==null||this.clientForm.clientUrl==""||this.clientForm.clientUrl=="undefined"){
        this.$message.error("公司网址不能为空!")
        return;
      }
      if (this.clientForm.clientUrl.length>255){
        this.$message.error("公司网址长度超出限制!")
        return;
      }

      if(this.clientForm.clientEmail==null||this.clientForm.clientEmail==""||this.clientForm.clientEmail=="undefined"){
        this.$message.error("公司邮箱不能为空!")
        return;
      }
      if (this.clientForm.clientEmail.length>255){
        this.$message.error("公司邮箱长度超出限制!")
        return;
      }

      if(this.clientForm.clientGzhName==null||this.clientForm.clientGzhName==""||this.clientForm.clientGzhName=="undefined"){
        this.$message.error("公司公众号名称不能为空!")
        return;
      }
      if (this.clientForm.clientGzhName.length>50){
        this.$message.error("公司公众号名称长度超出限制!")
        return;
      }

      if(this.clientForm.clientPhone==null||this.clientForm.clientPhone==""||this.clientForm.clientPhone=="undefined"){
        this.$message.error("公司联系方式不能为空!")
        return;
      }
      if (this.clientForm.clientPhone.length>255){
        this.$message.error("公司联系方式长度超出限制!")
        return;
      }
      // const regex = /^1\d{10}$/;
      // if(!regex.test(this.clientForm.clientPhone)){
      //   this.$message.error("请填写正确的11位手机号!")
      // }

        orCodeRegisterApi({...this.clientForm}).then(res => {
          if(res.code === 200){
            this.$message({
              type: 'success',
              message: "提交成功"
            })
            this.clientForm = this.$options.data().clientForm;
            this.$router.push({ path: '/submitOK'})
          }else {
            this.$message({
              type: 'error',
              message: res.desc
            })
          }
        })
    },
    resetClick(){
      this.clientForm = this.$options.data().clientForm;
    }
  },
};
</script>

<style scoped>
.form-container {
  margin: 0px auto;
  //padding: 20px;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
}

input {
  width: 90%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  display: block;
  margin-top: 10px;
}
</style>