<template>
  <div id="data-view">
    <dv-full-screen-container>
      <top-header/>
      <div class="main-content">
        <dataTop ref='dataTop' :dateType='dateType'/>
        <div class="block-left-right-content">
          <dataShow ref='dataShow' :dateType='dateType'/>
          <div class="block-top-bottom-content">
            <div class="block-top-content">
              <iprData ref='iprData' :dateType='dateType'/>
              <iprMark ref='iprMark' :dateType='dateType'/>
              <hotWord ref='hotWord' :dateType='dateType'/>
<!--              <industry ref='industry' :dateType='dateType'/>-->
<!--              <secBureau ref='secBureau' :dateType='dateType'/>-->
<!--              <dataStatistics ref='dataStatistics' :dateType='dateType'/>-->
            </div>
            <articleStatistics ref='articleStatistics' :dateType='dateType'/>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from './topHeader'
import dataTop from './dataTop'
import dataShow from './dataShow'
import articleStatistics from './articleStatistics'
import dataStatistics from './dataStatistics'
import industry from './industry'
import secBureau from './secBureau'
import iprData from 'views/data/iprData.vue'
import iprMark from 'views/data/iprMark.vue'
import hotWord from 'views/data/hotWord.vue'

export default {
  name: 'DataView',
  components: {
    dataStatistics,
    topHeader,
    dataTop,
    dataShow,
    articleStatistics,
    industry,
    secBureau,
    iprData,
    iprMark,
    hotWord
  },
  data () {
    return {
      dateType: "3",
      code:null,
      socket:null,
    }
  },
  created() {
    this.initWebSocket();
  },
  methods: {
    initWebSocket() {
      let ws = "";
      const currentProtocol = window.location.protocol;
      console.log('Current Protocol:', currentProtocol);
      if (currentProtocol === 'https:') {
        ws = "wss";
      } else {
        ws = "ws";
      }
      this.generateRandomString(6);
      const url = new URL(window.location.href);
      const host = url.host;
      const socketUrl = `${ws}://`+host+'/api/zckj/websocket/'+this.code;
      this.socket = new WebSocket(socketUrl);
      console.log("已开启websocket连接,当前连接:"+this.code)
      this.socket.onopen = this.websocketonopen;
      this.socket.onclose = this.websocketclose;
      this.socket.onerror = this.websocketonerror;
      this.socket.onmessage = this.websocketonmessage;
    },
    websocketonopen(message) {
      //连接建立之后执行send方法发送数据
      this.socket.send(message);
    },
    websocketclose(e) {
      //关闭
      console.log('断开连接', e);
      this.initWebSocket();
    },
    websocketonerror() {
      //连接建立失败重连
      console.log('进入了重新连接机制');
      this.initWebSocket();
    },
    websocketonmessage(e) {
      this.$refs.dataTop.initData();
      this.$refs.dataShow.initData();
      this.$refs.articleStatistics.initData();
      this.$refs.iprData.initData();
      this.$refs.iprMark.initData();
      // this.$refs.hotWord.initData();
    },
    generateRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      this.code = result;
    }
  }
}
</script>

<style lang="scss" scoped>
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    //flex: 1;
    display: flex;
    height: 100%;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    width: 100%;
    height: 350px;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }

  .bottom-content {
    display: flex;
  }
}
</style>