<template>
  <div style='height: 100%;display: grid;'>
    <div style='width: 45%;place-self: center;'>
      <el-card>
        <div style='margin: 0px auto'>
          <p style='text-align: center'>公司信息录入</p>
        </div>
        <div class="channel-add"style='padding-top: 50px;padding-bottom: 50px'>
          <el-form
            :model="clientForm"
            ref="clientForm"
            :rules="clientFormRules"
            :label-position="labelPosition"
            :label-width="formLabelWidth"
          >
            <el-form-item label="公司简称:" prop="clientName"  placeholder="请输入公司简称"   >
            <el-input v-model="clientForm.clientName" style="width: 300px"></el-input>
           </el-form-item>
            <el-form-item label="公司网址:" prop="clientUrl" placeholder="请输入公司网址">
              <el-input v-model="clientForm.clientUrl" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="公司邮箱:" prop="clientEmail" placeholder="请输入公司邮箱">
              <el-input v-model="clientForm.clientEmail" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="公司所在地(省):" prop="clientProvince" placeholder="请输入公司所在地">
              <el-input v-model="clientForm.clientProvince" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="公司所在地(市):" prop="clientCity" >
              <el-input v-model="clientForm.clientCity" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="公司联系方式:" prop="clientPhone" >
              <el-input v-model="clientForm.clientPhone" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="公司办公地址:" prop="clientWorkAddress"  >
              <el-input v-model="clientForm.clientWorkAddress" style="width: 300px"></el-input>
            </el-form-item>
          </el-form>
          <div align="center">
            <el-button type="primary" @click="clientRegisterClick">确定</el-button>
            <el-button type="primary" @click="resetClick">重置</el-button>
          </div>

        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { orCodeRegisterApi } from 'api/company'
export default {
  data() {
    return{
      formLabelWidth: '160px',
      labelPosition: 'right',
      clientForm: {
        clientName: '',
        clientEmail: '',
        clientProvince: '',
        clientCity: '',
        clientUrl: '',
        clientPhone: '',
        clientWorkAddress: '',
      },
      clientFormRules: {
        clientName: [{required: true, message: '公司简称不能为空',trigger: 'blur'}],
        clientEmail: [{required: true, message: '请输入正确邮箱',trigger: 'blur'}],
        clientProvince: [{required: true, message: '公司所在地不能为空',trigger: 'blur'}],
        clientCity: [{required: true, message: '公司所在地不能为空',trigger: 'blur'}],
        clientUrl: [{required: true,
          message: '公司网址不能为空',trigger: 'blur'}],
        clientPhone: [{
          required: true,
          pattern: /^((\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14})|([1][3,4,5,6,7,8,9][0-9]{9})$/,
          message: '请输入正确的公司电话',trigger: 'blur'}],
        clientWorkAddress: [{required: true, message: '公司办公地不能为空',trigger: 'blur'}],
      },
    }
  },
  created() {
    document.title = '动态宝系统';
    },
  methods: {
    clientRegisterClick(){
      this.$refs.clientForm.validate(valid => {
        if (valid) {
          orCodeRegisterApi({...this.clientForm}).then(res => {
            if(res.code === 200){
              this.$message({
                type: 'success',
                message: "提交成功"
              })
              this.clientForm = this.$options.data().clientForm;
              this.$router.push({ path: '/submitOK'})
            }else {
              this.$message({
                type: 'error',
                message: res.desc
              })
            }
          })
        }
      })
    },
    //重置
    resetClick(){
      this.clientForm = this.$options.data().clientForm;
      // this.$refs.clientForm.
    }
  }
}
</script>
<style>
</style>