var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%", display: "grid" } }, [
    _c(
      "div",
      { staticStyle: { width: "45%", "place-self": "center" } },
      [
        _c("el-result", {
          attrs: {
            icon: "success",
            title: "提交成功",
            subTitle: "感谢您的使用,贵公司信息已提交到动态宝系统中!",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }