<template>
  <div style='height: 100%;display: grid;'>
    <div style='width: 45%;place-self: center;'>
      <el-result icon="success" title="退订成功" subTitle="欢迎您使用动态宝系统!">
      </el-result>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  created() {
    document.title = '动态宝系统';
  },
  methods:{
  }
}
</script>

<style scoped lang='scss'>

</style>