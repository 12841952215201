// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-button--text[data-v-ae090572],\n.el-checkbox__input.is-checked + .el-checkbox__label[data-v-ae090572] {\n  color: #0096eb;\n}\n[data-v-ae090572] .el-button--text:hover {\n  color: #0096eb;\n}\n[data-v-ae090572] .el-button--text:focus {\n  color: #0096eb;\n}\n[data-v-ae090572] .tableHead {\n  margin-bottom: 0 !important;\n}\n[data-v-ae090572] .tableHead .cell {\n    font-weight: 900;\n    font-size: 14px;\n    background: #fafafa;\n}\n#data-view[data-v-ae090572] {\n  width: 100%;\n  height: 100%;\n  background-color: #030409;\n  color: #fff;\n}\n#data-view #dv-full-screen-container[data-v-ae090572] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 100% 100%;\n    box-shadow: 0 0 3px blue;\n    display: flex;\n    flex-direction: column;\n}\n#data-view .main-content[data-v-ae090572] {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}\n#data-view .block-left-right-content[data-v-ae090572] {\n    display: flex;\n    height: 100%;\n    margin-top: 20px;\n}\n#data-view .block-top-bottom-content[data-v-ae090572] {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n    padding-left: 20px;\n}\n#data-view .block-top-content[data-v-ae090572] {\n    width: 100%;\n    height: 350px;\n    display: flex;\n    flex-grow: 0;\n    box-sizing: border-box;\n    padding-bottom: 20px;\n}\n#data-view .bottom-content[data-v-ae090572] {\n    display: flex;\n}\n", ""]);
// Exports
module.exports = exports;
