var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "data-view" } },
    [
      _c(
        "dv-full-screen-container",
        [
          _c("top-header"),
          _c(
            "div",
            { staticClass: "main-content" },
            [
              _c("dataTop", {
                ref: "dataTop",
                attrs: { dateType: _vm.dateType },
              }),
              _c(
                "div",
                { staticClass: "block-left-right-content" },
                [
                  _c("dataShow", {
                    ref: "dataShow",
                    attrs: { dateType: _vm.dateType },
                  }),
                  _c(
                    "div",
                    { staticClass: "block-top-bottom-content" },
                    [
                      _c(
                        "div",
                        { staticClass: "block-top-content" },
                        [
                          _c("iprData", {
                            ref: "iprData",
                            attrs: { dateType: _vm.dateType },
                          }),
                          _c("iprMark", {
                            ref: "iprMark",
                            attrs: { dateType: _vm.dateType },
                          }),
                          _c("hotWord", {
                            ref: "hotWord",
                            attrs: { dateType: _vm.dateType },
                          }),
                        ],
                        1
                      ),
                      _c("articleStatistics", {
                        ref: "articleStatistics",
                        attrs: { dateType: _vm.dateType },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }