var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "top-header" } },
    [
      _c("dv-decoration-8", { staticClass: "header-left-decoration" }),
      _c("dv-decoration-5", { staticClass: "header-center-decoration" }),
      _c("dv-decoration-8", {
        staticClass: "header-right-decoration",
        attrs: { reverse: true },
      }),
      _c("div", { staticClass: "center-title" }, [_vm._v("动态宝实时数据")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }