<template>
  <div>
    <el-card>

    <div style="width: 80%; margin: 0 auto; font-family: Arial, sans-serif;">
      <h2 style="text-align: center;">媒介未回参链接说明</h2>
      <h3>
        由于技术限制或者其他因素，动态宝可能无法直接提供信息内容的原始链接，如需查看具体的信息内容，请参考如下截图或下载终端产品查看，感谢支持！
      </h3>
      <br>
      <h3>终端产品名称及展示位置如下：</h3>

      <el-row style="font-weight: bold; font-size: 20px;">
        <el-col span="8">终端产品名称</el-col>
        <el-col span="8">下载地址</el-col>
        <el-col span="8">展示位置</el-col>
      </el-row>

      <div v-for="(product, index) in products" :key="index" style="margin-top: 20px;">
        <el-row>
          <el-col span="8">
            <span style='font-weight: bold'>{{ product.name }}</span>
          </el-col>
          <el-col span="8">
            <a :href="product.downloadLink" v-if='product.downloadLink.includes("http")' >{{ product.downloadLink }}</a>
            <span  style='font-weight: bold' v-if='!product.downloadLink.includes("http")' >{{ product.downloadLink }}</span>
          </el-col>
          <el-col span="8">
            <span style='font-weight: bold'>{{ product.displayLocation }}</span>
          </el-col>
        </el-row>
        <img :src="require(`@/assets/images/channel/${product.imageSrc}`)" alt="" style="width: 30%; height: auto; margin-top: 10px;">
      </div>
    </div>
    </el-card>

  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          name: '聚源金融资讯终端（PC）',
          downloadLink: 'https://cloud.gildata.com/release/GFT3.exe',
          displayLocation: '个股详情页---“新闻”频道',
          imageSrc: 'juyuan.png'
        },
        {
          name: '中泰齐富通（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页-“资讯-新闻”频道',
          imageSrc: 'zhongtai.png'
        },
        {
          name: '东北证券融e通（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页-“资讯-新闻”频道',
          imageSrc: 'dongbei.png'
        },
        {
          name: '川财明佣宝（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '首页独立Tab-“动态”频道',
          imageSrc: 'chuancai.png'
        },
        {
          name: '国海金探号（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页-“新闻”频道',
          imageSrc: 'guohai.png'
        },
        {
          name: '民生财富汇（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '首页顶部Tab-“资讯中心-资讯-动态”频道',
          imageSrc: 'minsheng.png'
        },
        {
          name: '财达股市通（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '首页顶部Tab“更多应用-数据-企业动态”频道',
          imageSrc: 'caida.png'
        },
        {
          name: '中银证券（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“新闻/公告-新闻”频道',
          imageSrc: 'zhongyin.png'
        },
        {
          name: '中邮证券（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“新闻”频道',
          imageSrc: 'zhongyou.png'
        },


        {
          name: '诚通证券（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“新闻”频道',
          imageSrc: 'chengtong.png'
        },
        {
          name: '汇通启富（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“新闻”频道',
          imageSrc: 'huitong.png'
        },

        {
          name: '山西证券小富（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“新闻”频道',
          imageSrc: 'shanxi.png'
        },
        {
          name: '国盛通（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“资讯”频道',
          imageSrc: 'guosheng.png'
        },
        {
          name: '信达天下（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“资讯-新闻”频道',
          imageSrc: 'xinda.png'
        },
        {
          name: '储宝宝（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '个股详情页---“资讯-新闻”频道',
          imageSrc: 'chubaobao.png'
        },
        {
          name: '中山证券同花顺版（iOS&Android）',
          downloadLink: '应用商店',
          displayLocation: '首页独立Tab---“动态”频道',
          imageSrc: 'zhongshan.png'
        }
      ]
    };
  }
};
</script>

<style scoped>
.product {
  font-weight: bold;
  font-size: 18px;
}
</style>
