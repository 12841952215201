
<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">媒介统计</div>
    <div class="chart-container">
    <dv-capsule-chart :config="config" style="width:300px;height:200px" />
    </div>
  </div>
</template>

<script>
import { queryPushChannelTypeApi } from 'api/data';
export default {
  name: 'dataStatistics',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      config:{}
    }
  },
  created() {
    this.initData();
  },
  // mounted() {
  //   this.initData();
  // },
  methods:{
    initData(){
      queryPushChannelTypeApi(this.dateType).then(res => {
        if (res.code === 200){
          const result = res.result;
          const config = {
            data:[],
            rowNum:3
          };
          const data = [];
          result.forEach(item => {
            if (item.channelTypeName === "股票终端" || item.channelTypeName === "股票端"){
              const obj = {
                name: item.channelTypeName,
                value: item.num
              }
              data.push(obj);
            }
          })

          result.forEach(item => {
            if (item.channelTypeName === "券商终端" || item.channelTypeName === "券商端"){
              const obj = {
                name: item.channelTypeName,
                value: item.num
              }
              data.push(obj);
            }
          })

          result.forEach(item => {
            if (item.channelTypeName!="股票终端"&&item.channelTypeName!="股票端"&&item.channelTypeName!="券商端"&&item.channelTypeName!="券商终端"){
              const obj = {
                name: item.channelTypeName,
                value: item.num
              }
              data.push(obj);
            }
          })
          config.data = data;
          config.rowNum = 5;
          this.config = config;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#water-level-chart {
  width: 31%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>

<!--<template>-->
<!--  <div id="ranking-board">-->
<!--    <div class="ranking-board-title">传播统计</div>-->
<!--    <dv-capsule-chart :config="config" style="width:300px;height:200px" />-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: 'RankingBoard',-->
<!--  data () {-->
<!--    return {-->
<!--      config: {-->
<!--        data: [-->
<!--          {-->
<!--            name: '日常养护',-->
<!--            value: 55-->
<!--          },-->
<!--          {-->
<!--            name: '计日工',-->
<!--            value: 80-->
<!--          },-->
<!--          {-->
<!--            name: '路基',-->
<!--            value: 45-->
<!--          },-->
<!--        ],-->
<!--        rowNum: 9-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--#ranking-board {-->
<!--  width: 30%;-->
<!--  box-shadow: 0 0 3px blue;-->
<!--  display: flex;-->
<!--  flex-direction: column;-->
<!--  background-color: rgba(6, 30, 93, 0.5);-->
<!--  border-top: 2px solid rgba(1, 153, 209, .5);-->
<!--  box-sizing: border-box;-->
<!--  padding: 0px 30px;-->

<!--  .ranking-board-title {-->
<!--    font-weight: bold;-->
<!--    height: 50px;-->
<!--    display: flex;-->
<!--    align-items: center;-->
<!--    font-size: 20px;-->
<!--  }-->

<!--  .dv-scroll-ranking-board {-->
<!--    flex: 1;-->
<!--  }-->
<!--}-->
<!--</style>-->