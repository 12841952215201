<template>
  <div id="ranking-board" style="display: flex; flex-direction: column; width:20%;height: 100%;">
    <div class="ranking-board-title">BA公司排名</div>
    <dv-scroll-ranking-board :config="config" style="flex: 1;font-weight: bold" />
  </div>
</template>

<script>

import { queryLeftDataApi } from 'api/data'
export default {
  name: 'RankingBoard',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      config:{},
    }
  },
  created() {
    this.initData();
  },
  methods:{
    initData(){
      queryLeftDataApi(this.dateType).then(res=>{
        const config = {};
        const result = res.result;
        const data = [];
        result.forEach(item=>{
          data.push({
            name: item.stockName,
            value: item.sum
          })
        })
        config.data = data;
        config.rowNum = 7
        this.config = config;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#ranking-board {
  height: 100%;
  width: 50%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0px 30px;

  .ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .dv-scroll-ranking-board {
    flex: 1;
  }
}
</style>