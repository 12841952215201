<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">BA指标</div>
    <div class="chart-container">
      <chart-box :ref="'bottom1'" />
    </div>
  </div>
</template>

<script>
import { totalStatisticsApi, totalStatisticsByData, totalStatisticsByDataApi } from 'api/data'
import { frontChartBarData1 } from '@/util/js/homeCharts'
export default {
  name: 'dataStatistics',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      config:{}
    }
  },
  created() {
    this.initData();
  },
  methods:{
    initData(){
      totalStatisticsByDataApi().then(({ code, result }) => {
        if (code == 200) {
          const chartData = []
          const colorsArr = [
            '#00008b',
            '#ffde00',
            '#ed2939',
            '#024fa2',
            '#00247d',
            '#b22234',
            '#d52b1e',
            '#f93'
          ]
          let arr = [];
          let arr1 = [];
          this.totalStatisticsList = result.map((item, index) => {
            console.log(chartData,'totalStatisticsList');
            // item.colorStyle = colorsArr[index]
            chartData.push([item.data, item.id])
            arr.push(item.data)
            arr1.push(item.id)
            console.log(chartData,'chartData');
            console.log(arr,'chartData');
            console.log(arr1,'chartData');
            frontChartBarData1.xAxis.data = arr1
            frontChartBarData1.series[0].data = arr
            // frontChartBarData1.title[0].text = "本周数据排行"
            this.$refs.bottom1.draw(frontChartBarData1)
            return item
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#water-level-chart {
  width: 31%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>
