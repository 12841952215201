var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "water-level-chart" } }, [
    _c("div", { staticClass: "water-level-chart-title" }, [_vm._v("分发热词")]),
    _c("div", { staticClass: "chart-container" }, [
      _c(
        "div",
        { staticClass: "superposition Demo" },
        [
          _vm._l(_vm.superpositionData, function (d, index) {
            return _c(
              "div",
              {
                key: d.id,
                class: [
                  "superposition-item",
                  d.isTop ? "contain-After" : "contain-Before",
                  "superposition_" + index,
                ],
                style: { background: "rgb(" + d.rgb + ")" },
                on: {
                  click: function ($event) {
                    return _vm.handleBefore(d.id)
                  },
                },
              },
              [
                _c(
                  "p",
                  {
                    class: {
                      "normal-text": !d.isTop,
                      "reversed-text": d.isTop,
                    },
                  },
                  [_vm._v(_vm._s(d.isTop ? d.falseValue : d.value))]
                ),
              ]
            )
          }),
          _vm._l(_vm.superpositionData, function (d, index) {
            return _c(
              "div",
              {
                key: d.id,
                class: [
                  "superposition-item",
                  d.isTop ? "contain-Before" : "contain-After",
                  "superposition_" + index,
                ],
                style: { background: "rgb(" + d.rgb + ")" },
                on: {
                  click: function ($event) {
                    return _vm.handleBefore(d.id)
                  },
                },
              },
              [
                _c(
                  "p",
                  {
                    class: {
                      "normal-text": d.isTop,
                      "reversed-text": !d.isTop,
                    },
                  },
                  [_vm._v(_vm._s(d.isTop ? d.value : d.falseValue))]
                ),
              ]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }