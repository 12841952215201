var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        width: "20%",
        height: "100%",
      },
      attrs: { id: "ranking-board" },
    },
    [
      _c("div", { staticClass: "ranking-board-title" }, [_vm._v("BA公司排名")]),
      _c("dv-scroll-ranking-board", {
        staticStyle: { flex: "1", "font-weight": "bold" },
        attrs: { config: _vm.config },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }