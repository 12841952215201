<template>
  <div id="login" class="loginBg">
    <div class="login-title"></div>
    <div class="loginware">
      <div class="login-box">
        <div class="home_logo" @click="$router.push('/')">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="loginboard">
          <div class="loginboard-form">
            <el-form
              v-if="formType == 1"
              ref="firstForm"
              :model="firstForm"
              :rules="rules"
              label-width="0"
            >
              <div class="login-name">{{ pageTit }}</div>
              <el-form-item label="" prop="phoneNumber">
                <el-input
                  maxlength="11"
                  v-model="firstForm.phoneNumber"
                  placeholder="请输入账号手机号码"
                  @keyup.enter.native="submitForm('firstForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="checkCode">
                <div style="display: flex">
                  <el-input
                    v-model="firstForm.checkCode"
                    placeholder="请输入短信验证码"
                    @keyup.enter.native="submitForm('firstForm')"
                  ></el-input>
                  <el-button
                    type="primary"
                    class="verifyBtn"
                    @click="sendMsg"
                    >{{ sendVerify }}</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item label-width="0" class="">
                <el-button
                  type="primary"
                  class="subbtn"
                  @click="submitForm('firstForm')"
                  @keyup.enter.native="submitForm('firstForm')"
                  >下一步
                </el-button>
              </el-form-item>
            </el-form>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="secRule"
              label-width="0"
              v-if="formType == 2"
            >
              <div class="login-name">设置新密码</div>
              <el-form-item label="" prop="newPwd">
                <el-input
                  v-model="ruleForm.newPwd"
                  placeholder="设置6至20位登录密码"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="rePwd">
                <el-input
                  v-model="ruleForm.rePwd"
                  placeholder="请再次输入登录密码"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label-width="0" class="">
                <el-button
                  type="primary"
                  class="subbtn"
                  @click="submitForm('ruleForm')"
                  @keyup.enter.native="submitForm('ruleForm')"
                  >下一步
                </el-button>
              </el-form-item>
            </el-form>
            <div class="success" v-if="formType == 3">
              <i class="el-icon-circle-check"></i>
              <h2>密码重置成功</h2>
              <p>请妥善保管您的账户信息</p>
              <el-button type="primary" @click="successLogin"
                >重新登录</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userCodeApi, forgetPasswordApi } from '@/api/user/index'
export default {
  data() {
    let checkname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号/手机号'))
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('手机号格式错误!'))
      } else {
        callback()
      }
    }
    let checkpassword = (rule, value, callback) => {
      if (value === '') {
        callback('密码不能为空')
      } else if (value !== this.ruleForm.newPwd) {
        callback('密码需一致')
      } else {
        callback()
      }
    }
    return {
      pageTit: '重置密码',
      timer: '',
      sendVerify: '发送验证码',
      sendVerifyNum: 60, // 验证码倒计时
      identifyCode: 'abcd',
      ruleForm: {
        newPwd: '',
        rePwd: ''
      },
      firstForm: {
        checkCode: '',
        phoneNumber: ''
      },
      rules: {
        phoneNumber: [
          { required: true, message: '请输入手机号' },
          { validator: checkname, trigger: 'blur' }
        ],
        checkCode: [{ required: true, message: '请输入验证码' }]
      },
      secRule: {
        newPwd: [
          { required: true, message: '请输入新密码' },
          {
            min: 6,
            max: 20,
            message: '设置6至20位登录密码',
            trigger: 'blur'
          }
        ],
        rePwd: [
          { required: true, message: '请再次输入新密码' },
          { validator: checkpassword, trigger: 'blur' }
        ]
      },
      formType: 1
    }
  },
  created() {
    if (this.$route.query.type == 'find') {
      this.pageTit = '找回密码'
    }
  },
  methods: {
    sendMsg() {
      this.$refs.firstForm.validateField('phoneNumber', error => {
        if (!error) {
          this.success()
        }
      })
    },
    //图片验证码正确
    success() {
      if (this.sendVerifyNum === 60) {
        this.countDownTime()
      }
      if (this.quickLogin === 3) {
        this.submitFormNormal()
      }
    },
    // 倒计时效果
    countDownTime() {
      if (this.sendVerifyNum === 0) {
        this.sendVerifyNum = 60
        this.sendVerify = '发送验证码'
        return
      } else if (this.sendVerifyNum === 60) {
        // 此时进行验证码发送请求
        this.sendVerify = `${this.sendVerifyNum}s后重新获取`
        this.sendVerifyNum--

        this.getSmsCode()
      } else {
        this.sendVerify = `${this.sendVerifyNum}s后重新获取`
        this.sendVerifyNum--
      }
      // 实现倒计时效果
      this.timer = setTimeout(() => {
        this.countDownTime()
      }, 1000)
    },
    getSmsCode() {
      userCodeApi({
        mobile: this.firstForm.phoneNumber
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('验证码发送成功')
        } else {
          this.sendVerify = '发送验证码'
          this.sendVerifyNum = 60
          clearTimeout(this.timer)
        }
      })
    },

    successLogin() {
      this.$store.dispatch('user/logout')
      this.$router.push('/login')
    },
    submitForm(formName) {
      if (formName == 'ruleForm') {
        this.$refs[formName].validate(valid => {
          if (valid) {
            console.log(this.firstForm, this.ruleForm)
            forgetPasswordApi({ ...this.firstForm, ...this.ruleForm }).then(
              res => {
                if (res.code == 200) {
                  this.formType = 3
                }
              }
            )
          }
        })
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.formType = 2
          } else {
            return false
          }
        })
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.timer = null
  }
}
</script>
<style lang="scss" scoped>
.loginBg {
  position: relative;
  min-height: 100vh;
  // background: url('../../assets/images/bg5.jpg') no-repeat;
  background-size: 100%;
  background-size: cover;
  user-select: none;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.22);
  // }
}
.login-right {
  width: 350px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  color: beige;
  background: rgba(255, 255, 255, 0.22);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 28px;
  img {
    height: 216px;
  }
}
.loginware {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
  min-height: 640px;
  opacity: 0.8;
}
.login-box {
  width: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 540px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  .home_logo {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -65px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 80px;
      height: 80px;
    }
  }
}
.login-title {
  position: absolute;
  top: 0;
  left: 0;
  height: 128px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 100px;
  font-weight: 500;
  color: #ffffff;
  img {
    width: 145px;
    margin-right: 20px;
  }
}
.loginboard {
  // width: 90%;
  // max-width: 350px;

  .subbtn {
    margin-top: 30px;
    width: 100%;
    font-size: 16px;
    letter-spacing: 2px;
    // background-color: $themeColorBtn;
    // border-color: $themeColorBtn;
    border-color: #ffffff;
    background: transparent;
  }
}
.loginboard-title {
  padding-bottom: 40px;
  font-size: 24px;
  color: #fff;
  img {
    display: inline-block;
    margin-right: 16px;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    border-radius: 20px;
  }
}
.loginboard-form {
  width: 500px;
  box-sizing: border-box;
  padding: 10px 30px 20px;
  background: rgba(45, 52, 54, 1);
  height: 520px;
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /deep/.el-form {
    width: 80%;
  }
}
.login-name {
  text-align: center;
  font-weight: bold;
  margin: 0;
  color: beige;
  font-size: 30px;
  margin-bottom: 40px;
}
.identifyBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 43px;
  .valid {
    position: relative;
    margin-top: 1px;
    margin-left: 5px;
  }
}
.addr {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  p {
    padding: 0 0 10px;
    margin: 0;
  }
  .comp {
    width: 650px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 20px;
    p {
      line-height: 1;
      padding: 0;
    }
  }
}
/deep/ .el-input__inner {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  -webkit-text-fill-color: #fff; /* 设置字体颜色 */
  font-size: 18px;
  &::placeholder {
    background: transparent;
    color: #fff !important;
    font-size: 15px;
  }
  &:-webkit-autofill {
    background: transparent;
    color: #fff !important;
    transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
  }
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  i {
    color: greenyellow;
    font-size: 100px;
  }
  .el-button {
    width: 300px;
    margin-top: 20px;
  }
}
</style>
