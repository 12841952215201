var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "digital-flop" } },
    [
      _vm._l(_vm.digitalFlopData, function (item) {
        return _c(
          "div",
          { key: item.title, staticClass: "digital-flop-item" },
          [
            _c("div", { staticClass: "digital-flop-title" }, [
              _vm._v(_vm._s(item.title)),
            ]),
            _c(
              "div",
              { staticClass: "digital-flop" },
              [
                _c("dv-digital-flop", {
                  staticStyle: {
                    width: "200%",
                    height: "50px",
                    "text-align": "center",
                  },
                  attrs: { config: item.number, textAlign: "center" },
                }),
                _c("div", { staticClass: "unit" }, [_vm._v(_vm._s(item.unit))]),
              ],
              1
            ),
          ]
        )
      }),
      _c("dv-decoration-10"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }