export const chartsUser = {
  xAxis: {
    show: false,
    type: 'category',
    boundaryGap: false
    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    show: false,
    type: 'value'
  },
  grid: {
    top: '50px',
    left: '0px',
    right: '0px',
    bottom: '0px'
  },
  series: [
    {
      symbol: 'none',
      data: [0, 932, 901, 934, 1290, 1330, 1320, 0],
      type: 'line',
      smooth: true,
      itemStyle: {
        color: '#9DD3E8'
      },
      areaStyle: {}
    }
  ]
}
export const chartsNum = {
  xAxis: {
    show: false,
    type: 'category',
    boundaryGap: false
  },
  yAxis: {
    show: false,
    type: 'value'
  },
  grid: {
    top: '50px',
    left: '0px',
    right: '0px',
    bottom: '0px'
  },
  series: [
    {
      symbol: 'none',
      data: [0, 1330, 1320, 934, 1290, 901, 934, 0],
      type: 'line',
      smooth: true,
      itemStyle: {
        color: '#879BD7'
      },
      areaStyle: {}
    }
  ]
}

export const frontChartBarData1 = {
  tooltip: {},
  title: [
    {
      text: '',
      left: 'center',
      top: 10,
      textStyle: {
        fontWeight: 'normal',
        fontSize: 16
      }
    }
  ],
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      fontSize: 12,
      interval: 0,
      rotate: 45,
      color: '#ffffff'
    }
  },
  dataset: {
    source:
      [
        [7418, '发布量'],
        [3963, '渠道量'],
        [918, '企业量'],
        [918, '企业量'],
        [918, '企业量'],
        [918, '企业量'],
        [918, '企业量'],
        [918, '企业量']
      ]
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      textStyle: {
        color: '#ffffff' // Set text color to white for y-axis labels
      }
    },
    splitLine: {
      show: false // 将分割线隐藏
    }
  },

  series: [
    {
      data: [],
      type: 'bar',
      itemStyle: {
        normal: {
          //这里是颜色
          color: function(params) {
            //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
            var colorList = ['#5470C6','#91CC75', '#FAC858', '#3BA272', '#73C0DE','#11E2E3', '#FF8452'
              ,'#d52b1e','#D3B7D5','#C1E33B'];
            return colorList[params.dataIndex]
          }
        }
      }

    }

  ]
}

export const hotWordByData = [
  {
    id: 1,
    name: '动态宝网络',
    value: '光热发电',
    rgb: '90,154,215',
    falseValue: 'Pico4'
  },
  {
    id: 2,
    name: '动态宝网络',
    value: '超级电容',
    rgb: '255,192,0',
    falseValue: '二手房放松政策'
  },
  {
    id: 3,
    name: '动态宝网络',
    value: '国产替代',
    rgb: '158,72,13',
    falseValue: '虚拟电厂'
  },
  {
    id: 4,
    name: '动态宝网络',
    value: '小鹏G9',
    rgb: '152,115,0',
    falseValue: 'GPU'
  },
  {
    id: 5,
    name: '动态宝网络',
    value: '二手房放松政策',
    rgb: '99,99,99',
    falseValue: '光热发电'
  },
  {
    id: 6,
    name: '动态宝网络',
    value: '钠离子',
    rgb: '165,165,165',
    falseValue: '小鹏G9'
  },
  {
    id: 7,
    name: '动态宝网络',
    value: 'GPU',
    rgb: '112,172,72',
    falseValue: '钠离子'
  },
  {
    id: 8,
    name: '动态宝网络',
    value: '虚拟电厂',
    rgb: '68,114,199',
    falseValue: '电力交易'
  },
  {
    id: 9,
    name: '动态宝网络',
    value: '电力交易',
    rgb: '237,125,49',
    falseValue: '小鹏G9'
  },
  {
    id: 10,
    name: '动态宝网络',
    value: 'Pico4',
    rgb: '38,68,122',
    falseValue: 'GPU'
  }
]

export const divData = [
  [
    { id: 1, name: '动态宝网络', value: '东旭集团' },
    { id: 2, name: '动态宝网络', value: '东旭集团' },
    { id: 3, name: '动态宝网络', value: '东旭集团' },
    { id: 4, name: '动态宝网络', value: '东旭集团' },
    { id: 5, name: '动态宝网络', value: '东旭集团' },
    { id: 6, name: '动态宝网络', value: '东旭集团' }
  ],
  [
    { id: 7, name: '动态宝网络', value: '东旭集团' },
    { id: 8, name: '动态宝网络', value: '东旭集团' },
    { id: 9, name: '动态宝网络', value: '东旭集团' },
    { id: 10, name: '动态宝网络', value: '东旭集团' },
    { id: 11, name: '动态宝网络', value: '东旭集团' },
    { id: 12, name: '动态宝网络', value: '东旭集团' }
  ],
  [
    { id: 13, name: '动态宝网络1', value: '东旭集团' },
    { id: 14, name: '动态宝网络2', value: '东旭集团' },
    { id: 15, name: '动态宝网络3', value: '东旭集团' },
    { id: 16, name: '动态宝网络4', value: '东旭集团' },
    { id: 17, name: '动态宝网络5', value: '东旭集团' },
    { id: 18, name: '动态宝网络5', value: '东旭集团' }
  ],
  [
    { id: 19, name: '动态宝网络', value: '东旭集团' },
    { id: 20, name: '动态宝网络', value: '东旭集团' },
    { id: 21, name: '动态宝网络', value: '东旭集团' },
    { id: 22, name: '动态宝网络', value: '东旭集团' },
    { id: 23, name: '动态宝网络', value: '东旭集团' },
    { id: 24, name: '动态宝网络', value: '东旭集团' }
  ]
]

export const circleData = [
  [68, 30, 27],
  [48, 20, 17],
  [58, 50, 17]
]

//三环图
const gaugeData = [
  {
    value: 60,
    name: '发布量',

    title: {
      offsetCenter: ['0%', '-35%']
    },
    detail: {
      valueAnimation: true,
      offsetCenter: ['0%', '-20%']
    }
  },
  {
    value: 40,
    name: '媒介量',
    title: {
      offsetCenter: ['0%', '-5%']
    },
    detail: {
      valueAnimation: true,
      offsetCenter: ['0%', '10%']
    }
  },
  {
    value: 20,
    name: '企业量',
    title: {
      offsetCenter: ['0%', '25%']
    },
    detail: {
      valueAnimation: true,
      offsetCenter: ['0%', '40%']
    }
  }
]
export const sendChart = {
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          borderWidth: 1,
          borderColor: '#464646'
        }
      },
      axisLine: {
        lineStyle: {
          width: 40
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      data: gaugeData,
      title: {
        fontSize: 14
      },
      detail: {
        width: 50,
        height: 14,
        fontSize: 14,
        color: 'auto',
        borderColor: 'auto',
        borderRadius: 20,
        borderWidth: 1,
        formatter: '{value}%'
      }
    }
  ]
}
const countryColors = {
  Australia: '#00008b',
  Canada: '#f00',
  China: '#ffde00',
  Cuba: '#002a8f',
  Finland: '#003580',
  France: '#ed2939',
  Germany: '#000',
  Iceland: '#003897',
  India: '#f93',
  Japan: '#bc002d',
  'North Korea': '#024fa2',
  'South Korea': '#000',
  'New Zealand': '#00247d',
  Norway: '#ef2b2d',
  Poland: '#dc143c',
  Russia: '#d52b1e',
  Turkey: '#e30a17',
  'United Kingdom': '#00247d',
  'United States': '#b22234'
}
const colorsArr = [
  '#00008b',
  '#ffde00',
  '#ed2939',
  '#024fa2',
  '#00247d',
  '#b22234',
  '#d52b1e',
  '#f93'
]
export const frontChartBarData = {
  grid: {
    top: 10,
    bottom: 30,
    left: 100,
    right: 80
  },
  xAxis: {
    show: false,
    max: 'dataMax',
    axisLabel: {}
  },
  dataset: {
    source:
      // [
      //   [7418, 44.63431373, 3088808, 'Australia'],
      //   [3963, 45.12972, 0, 'Canada'],
      //   [918, 0, 377135349, 'China'],
      //   [2454, 39.54, 1658274, 'Cuba'],
      //   [2305, 44.61, 2358344, 'Finland'],
      //   [3639, 43.36, 40015501, 'France'],
      //   [3733, 40.91, 48211294, 'Germany'],
      //   [2009, 36.58, 96517, 'Iceland'],
      //   [1163, 24.384, 232819584, 'India'],
      //   [1606, 37.67568, 39878734, 'Japan'],
      //   [528, 26, 4959044, 'North Korea'],
      //   [526, 25.8, 9856047, 'South Korea'],
      //   [6265, 42.97564103, 669985, 'New Zealand'],
      //   [3251, 48.6, 2003954, 'Norway'],
      //   [2156, 37.41086957, 22618933, 'Poland'],
      //   [2233, 29.93047652, 59151534, 'Russia'],
      //   [1838, 35, 13188522, 'Turkey'],
      //   [7169, 44.75, 34215580, 'United Kingdom'],
      //   [5646, 45.21, 63810074, 'United States']
      // ]
      [
        [7418, '发布量'],
        [3963, '渠道量'],
        [918, '企业量']
        // [2454, 'Cuba'],
        // [2305, 'Finland'],
        // [3639, 'France'],
        // [3733, 'Germany'],
        // [2009, 'Iceland'],
        // [1163, 'India'],
        // [1606, 'Japan'],
        // [528, 'North Korea'],
        // [526, 'South Korea'],
        // [6265, 'New Zealand'],
        // [3251, 'Norway'],
        // [2156, 'Poland'],
        // [2233, 'Russia'],
        // [1838, 'Turkey'],
        // [7169, 'United Kingdom'],
        // [5646, 'United States']
      ]
  },
  yAxis: {
    type: 'category',
    inverse: true,
    max: function (value) {
      return value.max
    },
    axisLabel: {
      show: true,
      fontSize: 14,
      rich: {
        flag: {
          fontSize: 25,
          padding: 5
        }
      }
    },
    axisTick: {
      show: false // 隐藏y轴标线
    },
    axisLine: {
      show: false // 显示y轴线
    },
    animationDuration: 100,
    animationDurationUpdate: 100
  },
  series: [
    {
      // realtimeSort: true,
      seriesLayoutBy: 'column',
      type: 'bar',
      itemStyle: {
        color: function (param) {
          return colorsArr[param.dataIndex] || '#5470c6'
        }
      },
      encode: {
        x: 0,
        y: 3
      },
      label: {
        show: true,
        precision: 1,
        position: 'right',
        valueAnimation: true,
        fontFamily: 'monospace'
      }
    }
  ],
  animationDuration: 500,
  animationDurationUpdate: 1000,
  animationEasing: 'linear',
  animationEasingUpdate: 'linear'
  //底部
  // graphic: {
  //   elements: [
  //     {
  //       type: 'text',
  //       right: 160,
  //       bottom: 60,
  //       style: {
  //         text: 1890,
  //         font: 'bolder 80px monospace',
  //         fill: 'rgba(100, 100, 100, 0.25)'
  //       },
  //       z: 100
  //     }
  //   ]
  // }
}
