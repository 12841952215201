var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%", display: "grid" } }, [
    _c(
      "div",
      { staticStyle: { width: "45%", "place-self": "center" } },
      [
        _c("el-result", {
          attrs: {
            icon: "success",
            title: "退订成功",
            subTitle: "欢迎您使用动态宝系统!",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }