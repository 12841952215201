import apiServer from 'api/apiServer.js'


export function queryTopDataApi(params = {}) {
  let url = `/data/display/queryTopData/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function queryLeftDataApi(params = {}) {
  let url = `/data/display/queryLeftData/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function queryArticleListDataApi(params = {}) {
  let url = `/data/display/queryArticleListData/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}
// export function queryArticleListDataApi(params = {}) {
//   let url = `/data/display/articleReadNumRanking/${params}` //post methods
//   return apiServer.get(url).then(res => {
//     return Promise.resolve(res)
//   })
// }

export function queryReleaseRateApi(params = {}) {
  let url = `/data/display/queryReleaseRate/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function queryReleaseRateByDataApi(params = {}) {
  let url = `/data/display/queryReleaseRateByData/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}



export function queryPushChannelTypeApi(params = {}) {
  let url = `/data/display/queryPushChannelType/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function queryIndustryRankingApi(params = {}) {
  let url = `/data/display/queryIndustryRanking/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function querySecBureauRankingApi(params = {}) {
  let url = `/data/display/querySecBureauRanking/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getStockNameByStockNumApi(params = {}) {
  let url = `/stockData/getStockNameByStockNum` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getStockDataMarketApi(params = {}) {
  let url = `/stockData/getStockDataMarket` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getStockDataMarketAllApi(params = {}) {
  let url = `/stockData/getStockDataMarketAll` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getStockDataMarketSHApi(params = {}) {
  let url = `/stockData/getStockDataMarketSH` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getStockDataMarketHSApi(params = {}) {
  let url = `/stockData/getStockDataMarketHS` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getStockDataMarketByZZ500Api(params = {}) {
  let url = `/stockData/getStockDataMarketByZZ500` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getStockDataMarketByZZ1000Api(params = {}) {
  let url = `/stockData/getStockDataMarketByZZ1000` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getStockDataMarketByIPR100Api(params = {}) {
  let url = `/stockData/getStockDataMarketByIPR100` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getStockDataMarketByIPR300Api(params = {}) {
  let url = `/stockData/getStockDataMarketByIPR300` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getStockDataMarketByIPR500Api(params = {}) {
  let url = `/stockData/getStockDataMarketByIPR500` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


//IPR指标
export function totalStatisticsByDataApi(params = {}) {
  let url = `/white/statistics/totalStatisticsByData` //post methods
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

//分发热词
export function dynamicHotWordsApi(params = {}) {
  let url = `/white/website/data/dynamicHotWords`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function enterpriseDynamicsApi(params = {}) {
  let url = `/white/website/data/enterpriseDynamics`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getCarouselChartApi(params = {}) {
  let url = `/white/website/data/getCarouselChart`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}



