var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "container" } }, [
    _c(
      "div",
      { attrs: { id: "scroll-board" } },
      [_c("dv-scroll-board", { attrs: { config: _vm.config } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }