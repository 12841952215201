var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%", display: "grid" } }, [
    _c(
      "div",
      { staticStyle: { width: "45%", "place-self": "center" } },
      [
        _c("el-card", [
          _c("div", { staticStyle: { margin: "0px auto" } }, [
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v("公司信息录入"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "channel-add",
              staticStyle: { "padding-top": "50px", "padding-bottom": "50px" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "clientForm",
                  attrs: {
                    model: _vm.clientForm,
                    rules: _vm.clientFormRules,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司简称:",
                        prop: "clientName",
                        placeholder: "请输入公司简称",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.clientForm.clientName,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientForm, "clientName", $$v)
                          },
                          expression: "clientForm.clientName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司网址:",
                        prop: "clientUrl",
                        placeholder: "请输入公司网址",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.clientForm.clientUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientForm, "clientUrl", $$v)
                          },
                          expression: "clientForm.clientUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司邮箱:",
                        prop: "clientEmail",
                        placeholder: "请输入公司邮箱",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.clientForm.clientEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientForm, "clientEmail", $$v)
                          },
                          expression: "clientForm.clientEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司所在地(省):",
                        prop: "clientProvince",
                        placeholder: "请输入公司所在地",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.clientForm.clientProvince,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientForm, "clientProvince", $$v)
                          },
                          expression: "clientForm.clientProvince",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司所在地(市):", prop: "clientCity" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.clientForm.clientCity,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientForm, "clientCity", $$v)
                          },
                          expression: "clientForm.clientCity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司联系方式:", prop: "clientPhone" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.clientForm.clientPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientForm, "clientPhone", $$v)
                          },
                          expression: "clientForm.clientPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司办公地址:",
                        prop: "clientWorkAddress",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.clientForm.clientWorkAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientForm, "clientWorkAddress", $$v)
                          },
                          expression: "clientForm.clientWorkAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { align: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.clientRegisterClick },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }