var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", [
        _c(
          "div",
          {
            staticStyle: {
              width: "80%",
              margin: "0 auto",
              "font-family": "Arial, sans-serif",
            },
          },
          [
            _c("h2", { staticStyle: { "text-align": "center" } }, [
              _vm._v("媒介未回参链接说明"),
            ]),
            _c("h3", [
              _vm._v(
                " 由于技术限制或者其他因素，动态宝可能无法直接提供信息内容的原始链接，如需查看具体的信息内容，请参考如下截图或下载终端产品查看，感谢支持！ "
              ),
            ]),
            _c("br"),
            _c("h3", [_vm._v("终端产品名称及展示位置如下：")]),
            _c(
              "el-row",
              { staticStyle: { "font-weight": "bold", "font-size": "20px" } },
              [
                _c("el-col", { attrs: { span: "8" } }, [
                  _vm._v("终端产品名称"),
                ]),
                _c("el-col", { attrs: { span: "8" } }, [_vm._v("下载地址")]),
                _c("el-col", { attrs: { span: "8" } }, [_vm._v("展示位置")]),
              ],
              1
            ),
            _vm._l(_vm.products, function (product, index) {
              return _c(
                "div",
                { key: index, staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: "8" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(product.name)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: "8" } }, [
                        product.downloadLink.includes("http")
                          ? _c("a", { attrs: { href: product.downloadLink } }, [
                              _vm._v(_vm._s(product.downloadLink)),
                            ])
                          : _vm._e(),
                        !product.downloadLink.includes("http")
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(product.downloadLink))]
                            )
                          : _vm._e(),
                      ]),
                      _c("el-col", { attrs: { span: "8" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(product.displayLocation)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("img", {
                    staticStyle: {
                      width: "30%",
                      height: "auto",
                      "margin-top": "10px",
                    },
                    attrs: {
                      src: require("@/assets/images/channel/" +
                        product.imageSrc),
                      alt: "",
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }