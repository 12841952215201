<template>
  <div>
    <el-card>
      <p v-for='untreated in untreatedList' :key='untreated.articleId'>
        <span class='title'>
        {{untreated.articleTitle}}
        </span>
        <span class='stockName'>
        {{untreated.stockName}}
      </span>
        <span class='id'>
        {{untreated.articleId}}
      </span>
      </p>
    </el-card>
  </div>
</template>

<script>
import { getUntreatedListApi } from 'api/article'

export default {
  data(){
    return{
      untreatedList:[]
    }
  },
  mounted() {
    let href = window.location.href;
    href = decodeURIComponent(href);
    let channelName = href.split("=")[1];
    if (channelName) {
      this.getUntreatedList(channelName);
    }
  },
  methods:{
    getUntreatedList(channelName){
      getUntreatedListApi(channelName).then(res => {
        console.log("返回的数据:"+JSON.stringify(res))
        if(res.code === 200){
          this.untreatedList = res.result;
        }
      })
    }
  }
}
</script>



<style scoped lang='scss'>

.span{
  padding: 20px;
}
</style>