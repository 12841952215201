var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "water-level-chart" } }, [
    _c("div", { staticClass: "water-level-chart-title" }, [_vm._v("BA指标")]),
    _c(
      "div",
      { staticClass: "chart-container" },
      [_c("chart-box", { ref: "bottom1" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }