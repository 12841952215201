<template>
  <div style='height: 100%;display: grid;'>
    <div style='width: 45%;place-self: center;'>
<!--      <el-card>-->
        <el-result icon="success" title="提交成功" subTitle="感谢您的使用,贵公司信息已提交到动态宝系统中!">
<!--          <template slot="extra">-->
<!--            <el-button type="primary" size="medium" @click='returnBack'>返回</el-button>-->
<!--          </template>-->
        </el-result>
<!--      </el-card>-->
  </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {
    document.title = '动态宝系统';
  },
  methods:{
    returnBack(){
      window.close();
      this.$router.push({ path: '/transition'})
    }
  }
}
</script>

<style scoped lang='scss'>

</style>