<template>
  <div id="container">
<!--    <div id="water-level-chart">-->
<!--      <div id="main" style="width: 600px; height: 350px;"></div>-->
<!--    </div>-->
    <div id="scroll-board">
      <dv-scroll-board :config="config" />
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { getStockDataMarketApi, getStockDataMarketHSApi, queryArticleListDataApi } from 'api/data'
export default {
  name: 'articleStatistics',
    props:{
      dateType: {
        type: String,
        default: "0"
      }
    },
  data () {
    return {
      config:{}
    }
  },
  mounted() {
    // this.getIprRankingDataFun();
    this.initData();
  },
  methods:{
    getIprRankingDataFun(){
      const stockDataDto = {
        industryCode:"全部",
        type:2,
        stockNumValue:5
      }
      const hsDataDto = {
        type:2,
      }

      let apiPromises = [];

      apiPromises.push(getStockDataMarketApi(stockDataDto));
      apiPromises.push(getStockDataMarketHSApi(stockDataDto));

      Promise.all(apiPromises).then(values => {
        const stockMarketData = values[0].result;
        const dataMarketData = values[1].result;
        if (values[0].code === 200 && values[1].code === 200) {
          this.drawEchats(stockMarketData,dataMarketData);
        }
      })
    },
    drawEchats(stockData,data) {
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      // 处理股票市场数据
      var timeData = stockData.map(item => item.tradeDate.split(' ')[0]);
      var incomeData = stockData.map(item => parseFloat(item.totalValue).toFixed(2));
      var dataTimeData = data.map(item => item.tradeDate);
      var dataClosePriceData = data.map(item => parseFloat(item.closePrice).toFixed(2));

      console.log("incomeData"+incomeData)
      console.log("dataClosePriceData"+dataClosePriceData)

      var stockChange = ((incomeData[incomeData.length - 1] - incomeData[0]) / incomeData[0] * 100).toFixed(2);
      var dataChange = ((dataClosePriceData[dataClosePriceData.length - 1] - dataClosePriceData[0]) / dataClosePriceData[0] * 100).toFixed(2);

      console.log("stockChange"+stockChange)
      console.log("dataChange"+dataChange)
      // 计算涨跌量
      var stockVolumeChange = (incomeData[incomeData.length - 1] - incomeData[0]).toFixed(2);
      var dataVolumeChange = (dataClosePriceData[dataClosePriceData.length - 1] - dataClosePriceData[0]).toFixed(2);

      option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            var dynamicData = params[1].value; // 假设动态数据在第一个系列
            var iprData = params[0].value; // 假设IPR数据在第二个系列
            var change = ((iprData - dynamicData) / dynamicData * 100).toFixed(2); // 计算涨跌幅度，保留两位小数
            return params[0].axisValueLabel + '<br/>' +
              params[0].marker + params[0].seriesName +":"+ iprData+ '  相对涨跌: ' + change + '%'+'<br/>' +
              params[1].marker + params[1].seriesName + ': ' + dynamicData;
          }
        },
        legend: {
          data: [
            {
              name: `IPR指数 (前20名)`,
              textStyle: {
                color: 'red' // 设置文本颜色为红色
              }
            },
            {
              name: `沪深300`,
              textStyle: {
                color: 'purple' // 设置文本颜色为红色
              }
            },
          ]
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeData,
          axisLabel: {
            interval: 'auto', // 自动计算间隔
            textStyle: {
              color: '#ffffff' // Set text color to white for x-axis labels
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#ffffff' // Set text color to white for y-axis labels
            }
          }
        },
        series: [
          {
            name: `IPR指数 (前20名)`,
            type: 'line',
            data: incomeData,
            smooth: true,
            symbol: 'none', // 为股票市场数据去掉圆圈
            lineStyle: {
              color: 'red' // 设置线条颜色为红色
            },
            itemStyle: {
              // 设置鼠标悬停时的数据样式
              color: 'red' // 设置悬停时的数据颜色为红色
            }
          },
          {
            name: `沪深300`,
            type: 'line',
            data: dataClosePriceData,
            smooth: true,
            symbol: 'none', // 为股票市场数据去掉圆圈
            lineStyle: {
              color: 'blue' // 设置线条颜色为红色
            },
            itemStyle: {
              // 设置鼠标悬停时的数据样式
              color: 'blue' // 设置悬停时的数据颜色为红色
            }
          }
        ],
        title: {
          text: [
            `涨跌幅：IPR指数 (前20名) ${stockChange}% | 沪深300 ${dataChange}%`,
            `涨跌量：IPR指数 (前20名) ${stockVolumeChange} | 沪深300 ${dataVolumeChange}`
          ].join('\n'), // 使用\n来分行
          left: 'center',
          top: 'bottom', // 将标题移动到底部
          textStyle: {
            lineHeight:20,
            fontSize: 14,
            align: 'center', // 确保文本居中对齐
            color: '#ffffff' // Set text color to white
          }
        }
      };
      myChart.setOption(option);
    },
    initData(){
      queryArticleListDataApi(this.dateType).then(res =>{
        if (res.code === 200){
          const result = res.result;
          const config = {
            header: ['公司名称', '文章标题', '分发时间', '分发媒介数'],
            data: result.map(item => [item.stockName, item.articleTitle, item.checkTime, item.pushApiSum]),
            index: true,
            // columnWidth: [50, 100, 200,180,100],
            columnWidth: [100, 100, 600,200,100],
            align: ['center'],
            rowNum: 5,
            headerBGC: '#1981f6',
            // carousel: 'single',
            // hoverPause:tr,
            oddRowBGC: 'rgba(0, 44, 81, 0.8)',
            evenRowBGC: 'rgba(10, 29, 50, 0.8)',
        }
          this.config = config;
        }
      })
    }
  }
}
</script>

<style scoped>
#container {
  display: flex; /* Use flexbox */
  height: 100%;
}

#water-level-chart {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
}

#scroll-board {
  width: 98%; /* Adjust width */
  height: 100%;
  margin-left: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
</style>