<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">BA指数</div>
    <div class="chart-container">
      <div id="main" style="width: 500px; height: 250px;"></div>
      <!--      <dv-capsule-chart :config="config" style="width:300px;height:200px" />-->
    </div>
  </div>
</template>

<script>
import {
  getStockDataMarketAllApi,
  getStockDataMarketApi, getStockDataMarketByIPR300Api, getStockDataMarketByZZ1000Api, getStockDataMarketByZZ500Api,
  getStockDataMarketHSApi,
  getStockDataMarketSHApi
} from 'api/data'
import * as echarts from 'echarts'

export default {
  name: 'dataStatistics',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      config:{}
    }
  },
  created() {
    this.initData();
  },
  methods:{
    initData(){
      const stockDataDto = {
        industryCode:"全部",
        type:2,
        stockNumValue:5
      }
      const hsDataDto = {
        type:2,
      }

      let apiPromises = [];
      const pageMsg = {
        weightType:0
      }
      apiPromises.push(getStockDataMarketAllApi(pageMsg));
      apiPromises.push(getStockDataMarketSHApi(pageMsg));
      apiPromises.push(getStockDataMarketHSApi(pageMsg));
      apiPromises.push(getStockDataMarketByZZ500Api(pageMsg));
      apiPromises.push(getStockDataMarketByZZ1000Api(pageMsg));
      apiPromises.push(getStockDataMarketByIPR300Api(pageMsg));
      Promise.all(apiPromises).then(values => {
        const quanAData = values[0].result;
        const shangZhengData = values[1].result;
        const huShen300Data = values[2].result;
        const zhongZheng500Data = values[3].result;
        const zhongZheng1000Data = values[4].result;
        const ipr300Data = values[5].result;
        this.drawEchats(quanAData,shangZhengData,huShen300Data,zhongZheng500Data,zhongZheng1000Data,ipr300Data)
      });
    },
    drawEchats(quanAData,shangZhengData,huShen300Data,zhongZheng500Data,zhongZheng1000Data,ipr300Data,){

      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;
      var timeData = ipr300Data.map(item => item.tradeDate.split(' ')[0]);
      var quanADataChange = [];
      var shangZhengDataChange = [];
      var huShen300DataChange = [];
      var zhongZheng500DataChange = [];
      var zhongZheng1000DataChange = [];
      var ipr300DataChange = [];

      for (let i = 0; i < quanAData.length; i++) {
        if (i === 0) {
          quanADataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((quanAData[i].closePrice - quanAData[0].closePrice) / quanAData[0].closePrice * 100).toFixed(2);
          quanADataChange.push(change);
        }
      }

      for (let i = 0; i < shangZhengData.length; i++) {
        if (i === 0) {
          shangZhengDataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((shangZhengData[i].closePrice - shangZhengData[0].closePrice) / shangZhengData[0].closePrice * 100).toFixed(2);
          shangZhengDataChange.push(change);
        }
      }

      for (let i = 0; i < huShen300Data.length; i++) {
        if (i === 0) {
          huShen300DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((huShen300Data[i].closePrice - huShen300Data[0].closePrice) / huShen300Data[0].closePrice * 100).toFixed(2);
          huShen300DataChange.push(change);
        }
      }

      for (let i = 0; i < zhongZheng500Data.length; i++) {
        if (i === 0) {
          zhongZheng500DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((zhongZheng500Data[i].closePrice - zhongZheng500Data[0].closePrice) / zhongZheng500Data[0].closePrice * 100).toFixed(2);
          zhongZheng500DataChange.push(change);
        }
      }

      for (let i = 0; i < zhongZheng1000Data.length; i++) {
        if (i === 0) {
          zhongZheng1000DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((zhongZheng1000Data[i].closePrice - zhongZheng1000Data[0].closePrice) / zhongZheng1000Data[0].closePrice * 100).toFixed(2);
          zhongZheng1000DataChange.push(change);
        }
      }

      for (let i = 0; i < ipr300Data.length; i++) {
        if (i === 0) {
          ipr300DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((ipr300Data[i].closePrice - ipr300Data[0].closePrice) / ipr300Data[0].closePrice * 100).toFixed(2);
          ipr300DataChange.push(change);
        }
      }

      // 准备图表的配置选项
      var option = {
        // 图表标题
        title: {
          // text: '指数对比'
        },
        // 提示框组件
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            var result = params[0].axisValue + '<br/>';
            params.forEach(function(item) {
              var index = item.seriesIndex;
              result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + item.color + '"></span>'; // 添加小圆点
              // var closePrice = parseFloat([quanAData, shangZhengData, huShen300Data, zhongZheng500Data, zhongZheng1000Data,  ipr300Data][index][item.dataIndex].closePrice); // 将字符串转换为数字
              var closePrice = parseFloat([ ipr300Data,huShen300Data, zhongZheng500Data, zhongZheng1000Data][index][item.dataIndex].closePrice); // 将字符串转换为数字
              result += item.seriesName + '：' + closePrice.toFixed(2) + '  ,   ' + item.data +'%';
              result += '<br/>';
            });
            return result;
          }
        },
        // 图例组件
        legend: {
          data: [
            {
              name: `BA300`,
              textStyle: {
                color: '#d52b1e' // 设置文本颜色为红色
              }
            },
            {
              name: `沪深300`,
              textStyle: {
                color: '#d52b1e' // 设置文本颜色为红色
              }
            },
            {
              name: `中证500`,
              textStyle: {
                color: '#d52b1e' // 设置文本颜色为红色
              }
            },
            {
              name: `中证1000`,
              textStyle: {
                color: '#d52b1e' // 设置文本颜色为红色
              }
            },

          ]
        },
        // X轴配置
        xAxis: {
          type: 'category',
          data: timeData // 使用时间数据
        },
        // Y轴配置
        yAxis: {
          type: 'value'
        },
        // 数据系列
        series: [
          {
            name: 'BA300',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: ipr300DataChange
          },
          {
            name: '沪深300',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: huShen300DataChange
          },
          {
            name: '中证500',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: zhongZheng500DataChange
          },
          {
            name: '中证1000',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: zhongZheng1000DataChange
          },

        ],
      };
      myChart.setOption(option);
    },
  }
}
</script>

<style lang="scss" scoped>
#water-level-chart {
  width: 31%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>
