var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        _vm._l(_vm.untreatedList, function (untreated) {
          return _c("p", { key: untreated.articleId }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(untreated.articleTitle) + " "),
            ]),
            _c("span", { staticClass: "stockName" }, [
              _vm._v(" " + _vm._s(untreated.stockName) + " "),
            ]),
            _c("span", { staticClass: "id" }, [
              _vm._v(" " + _vm._s(untreated.articleId) + " "),
            ]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }